import React from 'react'
import useApp from './AppContext';
import { useNavigate } from "react-router-dom";

const LogOut = () => {
    const app = useApp();
    let navigate = useNavigate();

    if (app.user) {
        app.setUser(null); 
        navigate("/");
    }
  return (
    <div>logout</div>
  )
}
export default LogOut
