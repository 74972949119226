import React from 'react';

function NumericInput({ value, onChange, onKeyDown, min, max, disabled }) {
  const handleChange = (event) => {
    if (event.target.value ==='' ){event.target.value =0 }
    onChange(event);
  };

  return (
    <input
      type="number"
      value={value === null ? '' : value}
      onChange={handleChange}
      onKeyDown={onKeyDown}
      min={min}
      max={max}
      disabled={disabled}
    />
  );
}

export default NumericInput;
