import { useState, useEffect } from 'react';
import { callApi, ErrorAlert } from './utils/utils.js';
import SideBarMenu from './SideBarMenu.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import {  Outlet } from "react-router-dom";
function MainPage() {
  const [serverUrl] = useState(process.env.REACT_APP_SERVER_URL);
  const [sideBarLeftMargin, setSideBarLeftMargin] = useState(0);
  const [sideMenuOptions, setSideMenuOptions] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function getLayout() {
      try {
        setSideMenuOptions(await callApi('GET', `${serverUrl}/api/Services/GetMenu`));
      } catch (err) {
        setError(err.message);
      }
    };
    getLayout();
  }, [serverUrl]);

  return (<div id="page" className="d-flex content-background">
    <nav id="sidebar" style={{ marginLeft: sideBarLeftMargin }}>
      <div id="sidebar-content">
        <div id="sidebar-collapse-wrapper" style={{right:sideBarLeftMargin?-25:25}}>
          <button type="button" id="sidebar-collapse" className="btn btn-primary" onClick={() => sideBarLeftMargin ? setSideBarLeftMargin(0) : setSideBarLeftMargin(-250)}>
            <FontAwesomeIcon icon={faBars} />
          </button>
        </div>
        <div className="p-4 pt-3">
          <h1><a href="/" className="logo">Service<span className="e-Service"></span></a></h1>
          <SideBarMenu options={sideMenuOptions} />
        </div>
      </div>
    </nav>
    <div id="content" className="p-0">
      <ErrorAlert message={error} />
      <Outlet />
    </div>
  </div>)
 
}
export default MainPage