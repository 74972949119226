import React, { useEffect, useState, useRef, useCallback }  from 'react'
import BarcodeReader from './BarcodeReader';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function ScannControl({ onChange, disabled, onError }){
    const [scannedBarcodes, setScannedBarcodes] = useState([]);
    const prevScannedBarcodesRef = useRef([]);
    const barcodeInput = useRef(null);
    const [error, setError] = useState(null);


    useEffect(() => {
        prevScannedBarcodesRef.current = scannedBarcodes;
    }, [scannedBarcodes]);
    
    useEffect(() => {
        if (barcodeInput.current ===null ) return;
        let bcInputCurrent = barcodeInput.current;
      //  bcInputCurrent.focus();
        bcInputCurrent.addEventListener('keydown', onKeyDown);
        function onKeyDown(e) {
            if (e.keyCode === 13 || e.keyCode ===9 ) {
                validateScan(barcodeInput.current.value);
               // barcodeInput.current.focus();
            }
        }
        return function cleanup() {
            bcInputCurrent.removeEventListener('keydown', onKeyDown);
        };
    });

    const validateScan = useCallback(async function(bc) {
        setError(null);
        if (bc === null) return;
        if (prevScannedBarcodesRef.current !== null && prevScannedBarcodesRef.current.filter(t => t.barcode === bc).length) {
            setError("Already scanned");
            onError("Already scanned");
            return;
        }

        setScannedBarcodes(prevScan=>{
            let newData=[...prevScan];
            newData.push({barcode: bc});
            return newData;
        }); 
        barcodeInput.current.value = bc;     
        onChange(bc);    
    },[onChange,onError]);


  function Onclose(val){
    barcodeInput.current.value =null;
    barcodeInput.current = null;
    setScannedBarcodes([]);
  }  

  return (
    <Form.Group key="" as={Row} className="label-and-control">
        <Col sm={8}>
        <Form.Control id="bc" disabled ={disabled}  ref={barcodeInput} type="text" placeholder="Scan Barcode" /></Col>
        {
            disabled ===false ?
            <Col sm={4} >
                <BarcodeReader 
                onScan={validateScan}
                onError={error} 
                onClose ={Onclose}
                onChange={onChange}
                >
                </BarcodeReader>
            </Col>
        :   null
        }
    </Form.Group>    
  )
}

export default ScannControl