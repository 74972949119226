import { Routes, BrowserRouter, Route,useLocation,Navigate } from "react-router-dom";
import './App.css';

import Home from "./Home.js";
import NoMatch from './NoMatch.js'
import Login from "./Login.js";
import List from "./List.js";
import useApp, { AppContextProvider } from './AppContext';
import LogOut from "./logout.js";
import MainPage from "./MainPage.js";

function RequireAuth({ children }) {
  let app = useApp();
  let location = useLocation();
  if (!app.user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}

function App() {
  return (
    <AppContextProvider>
      <BrowserRouter>
        <Routes>
        <Route path="/" element={<MainPage />}>
            <Route index element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<LogOut />} />
            <Route path="list/:layoutId" element={<RequireAuth><List /></RequireAuth>} />
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </BrowserRouter>
      </AppContextProvider>
  );
}

export default App;
