import React from 'react';
import DatePicker , { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './TxtDatePicker.css';
import { el } from 'date-fns/locale';
import { FaCalendarAlt } from 'react-icons/fa';
import { FcCalendar } from "react-icons/fc";
//import { ReactComponent as FaCalendarAlt } from 'bootstrap-icons/icons/calendar3.svg';
registerLocale('el', el);

function TxtDatePicker({ value, onChange, disabled }){

    function CalendarInput({value ,onClick}){

        return(
            <div className='input-group Calendar-input'>
                <input type ='text' className='form-control' value={value} onClick={onClick} readOnly/>
                <div className='input-group-append'>
                    <span className='Input-group-Icon' >
                        <FcCalendar/>
                    </span>
                </div>
            </div>
    );
    }
    const handleDateChange = (date) => {
        onChange(date);
      };

    return (
        <label className='Calendar'>
            <DatePicker
            selected={value}
            onChange={handleDateChange}
            dateFormat="dd/MM/yyyy"
            placeholderText="Select a date"
            disabled={disabled}
            todayButton={"Today"}
            locale="el"
            slots={FaCalendarAlt}
            customInput ={<CalendarInput/>} />
        </label>
  )
}

export default TxtDatePicker