//import { useParams,useSearchParams } from "react-router-dom";
import { useParams } from "react-router-dom";

import TableView from "./TableView";
import RoteList from "./Routes/RoteList";
import ConfirmRoute from "./Routes/ConfirmRoute";
//import CrudList from "./crud/CrudList";

export async function loader({ params }) {
  const layoutId = params.layoutId;
  return layoutId;
}

export default function List() {
  const params = useParams();
 // let [searchParams, setSearchParams] = useSearchParams();
  if (!params.layoutId) return null;
  if (params.layoutId  === "Route"){
    return <RoteList layoutUrl={`api/Services/${params.layoutId}`} /> 
  }
  else if (params.layoutId  === "ConfirmRoute"){
    return <ConfirmRoute layoutUrl={`api/Services/Route`} /> 
  }
  else {
    return <TableView layoutUrl={`api/Services/${params.layoutId}`} /> 
  }

  /*
  if (searchParams && searchParams.toString()){
    return <CrudList userToken="" layoutUrl={`api/layouts/list/${params.layoutId}?${searchParams.toString()}`} />
  } else{
    return <CrudList userToken="" layoutUrl={`api/layouts/list/${params.layoutId}`} />
  }
  <h1>{params.layoutId}</h1>
  */
  
  
}