import Alert from 'react-bootstrap/Alert';

export async function callApi(method, url, token, body, contentType, defaultHeaders) {

    let headers = defaultHeaders ? defaultHeaders : {};
    if (token) Object.assign(headers, { 'Authorization': `Bearer ${token}` });
    if (contentType) Object.assign(headers, { "Content-Type": contentType });
    else Object.assign(headers, { "Content-Type": "application/json" });    
    
    let options = {
        method: method,
        headers: headers,
        body: (!body || (contentType && contentType!=="application/json")) ? body : JSON.stringify(body),
        cache: "no-store"
    };
    try {
        let response = await fetch(url, options);
        if (!response.ok) {
            let body=await response.text();
            if (body){
                throw new Error(body);
            }else{
                throw new Error(`Server returned status:${response.status} ${response.statusText}, Details:${body}, Type: ${response.type}.`);
            }
        }
        if (contentType ==="text/html; charset=UTF-8") response = await response.text();
        else if (contentType === "text/javascript") response = await response.text();
        else response = await response.json();
        if (response && response.errors && response.errors.length) {
            throw new Error(`Error: ${response.errors[0].message}`);
        }
        return response;
    } catch (err) {
        throw new Error(err.message);
    }
}

export function ErrorAlert(props) {
    if (!props || !props.message) return null;
    return (
        <Alert variant="danger">
            <p className="mb-0">
                {props.message}
            </p>
        </Alert>
    );
}


export function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  export function formatDateValue(colValue,format){
    if (!colValue) return colValue;
    let retVal=colValue;
    let d = null;
    if (retVal.length>=13) d=new Date(retVal);
    if (!(d instanceof Date) || isNaN(d)) return retVal;
    if (format === 'd') {
        retVal = d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
    } else if (format === 't') {
        if (colValue.length<=5 && !validateTime(colValue)) return colValue;
        retVal = d.getHours().toString().padStart(2, '0') + ":" + d.getMinutes().toString().padStart(2, '0');
    } else if (format === 'dt') {
        if (!validateDate(colValue)) return colValue;
        retVal = d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear() + " " +
            d.getHours().toString().padStart(2, '0') + ":" + d.getMinutes().toString().padStart(2, '0');
    }
    return retVal;
} 

function validateTime(s,throwError){
    if (s.length!==5 ||
        s.substr(2,1)!==":" ||
        isNaN(s.substr(0,2)) ||
        isNaN(s.substr(3,2)) ||
        parseInt(s.substr(0,2))<0 || parseInt(s.substr(0,2))>'23' ||
        parseInt(s.substr(3,2))<0 || parseInt(s.substr(3,2))>'59'
    ){
        if (throwError){
            throw new Error(`${s} is not a valid time`);
        }else{
            return false;
        }
    }
    return true;
}
function validateDate(s,throwError){
    let isValid=isDateValid(s);
    if (!isValid){
        if (throwError){
            throw new Error(`${s} is not a valid date `);
        }else{
            return false;
        }
    }
    return true;
} 

export function isDateValid (strDate) {
    let date = stringToDate(strDate);
    if (date === "Invalid Date" || isNaN(date) || !date) {
        return false;
    }
    return true;
}

export function strDateToUriString(strDate){
    let d=stringToDate(strDate);
    return d.toUTCString();
}

export function stringToDate(str) {
    if (!str) return null;
    let parts = str.split('/');
    if (parts.length !== 3) return null;
    try{
        let yearParts=parts[2].split(' ');     
        if (yearParts.length===1){
            return(new Date(parts[2], parts[1]-1, parts[0]));    
        } else if (yearParts.length!==2){
            return null;
        }
        let year=yearParts[0];
        let timeParts=yearParts[1].split(':');
        if (timeParts.length!==2) return null;
        return(new Date(year, parts[1]-1, parts[0],timeParts[0],timeParts[1]));
    }catch {
        return null;
    }
}

export function dateToIsoString(d) {
    return d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
}
export function dateToString(d) {
    return  d.getDate().toString()+ "/" + (d.getMonth() + 1) + "/" +d.getFullYear().toString();
}
export function dateToTimeString(d) {
    return d.getHours().toString().padStart(2, '0') + ":" + d.getMinutes().toString().padStart(2, '0');
}

export function getPropValue (obj, propPath) {
    let pathArray = propPath.split('.');

    return pathArray.reduce((o, key) => {
        return (o && o[key] !== 'undefined') ? o[key] : null
    },obj);
}

export function setPropValue (obj, propPath,value) {
    if (!obj) return;
    let pathArray = propPath.split('.');
    let o=obj;
    pathArray.forEach((propertyName,i)=>{
        if (i===pathArray.length-1) o[propertyName]=value;
        else {
            if (!o[propertyName]) o[propertyName]={};
            o=o[propertyName];

        };
    });
}

export function Round(value, decimals) {
    return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}  

