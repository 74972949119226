import React, { useEffect ,useState }  from 'react'
import useApp from '../AppContext'
import { callApi, ErrorAlert,formatDate,formatDateValue,dateToString }  from '../utils/utils'
import RouteForm from './RouteForm';

function RoteList(props){
  const app = useApp();
  const [error, setError] = useState(null);
  const [serverUrl] = useState(process.env.REACT_APP_SERVER_URL);
  const [data, setData] = useState(null);
  const [routDate, setRoutDate] = useState(null);
  const [editRowIndex, setEditRowIndex] = useState(null);
  const [editModeActive, setEditModeActive] = useState(false);
  const [refreshData, setRefreshData] = useState(true);

  useEffect(() =>{
    async function getLayout() {
      try {
        if (!app.user) return;
        if (refreshData === false ) return;
        let rDate = new Date();
        const request =await callApi('GET', `${serverUrl}/${props.layoutUrl}?TechnicalID=${app.user.technicalId}&RoutDate=${formatDate(rDate)}` );
        setData(request["data"]);
        setRoutDate(rDate);
        setRefreshData(false);
      } catch (err) {
        setError(err.message);
      }
    };   
    getLayout();
  },[props.layoutUrl,serverUrl,app,refreshData]);

  function  handleClick(id){
    setEditRowIndex(id);
    setEditModeActive(true);
  };
  function getOriginalObj(){
    if (data ===null ) return;
    let obj = data[editRowIndex];
    if (typeof obj["PaymentTypeId"] == "undefined") {obj["PaymentTypeId"] =null;};
    if (typeof obj["OrderType"] == "undefined") {obj["OrderType"] =0;};
    if (obj["OrderId"] !==null){ obj["OrderType"]=1; }
    if (typeof obj["OrdItems"] == "undefined") { obj["OrdItems"] =[] };
    if (typeof obj["OrdNotes"] == "undefined") { obj["OrdNotes"] =''};
    if (typeof obj["Deposit"] == "undefined") { obj["Deposit"] =0};
    if (typeof obj["CashFlg"] == "undefined") { obj["CashFlg"] =true};
    if (typeof obj["AddMonth"] == "undefined") { obj["AddMonth"] =12};        
    if (obj["PaymentTypeId"] ===3 ) { obj["CashFlg"] =false};
    if (obj["OrderId"] ==null && obj["CustomerId"] ==null){
      obj["Status"]=1;
    }



    if ((typeof obj["OrdItems"] != "undefined")){
      obj.TotalPrice=0;
      obj.OrdItems.forEach(rowItem => {
        obj.TotalPrice +=rowItem.TotalPrice;
      });
    }


    return obj;
}
  function createRows() {
    if (!data) return null;
    return data.map((obj, i) => {
        return (
          <div id={i} class={ obj['Status'] ===1 ? 'row datarow datarowGreen': 'row datarow' } onDoubleClick={() => handleClick(i)}>
            <div  class="d-flex col-sm-12">
              <div class="col-sm-1 bold">{`Ωρα : ${formatDateValue(obj["AppDate"],'t')}`}</div>  
              <div class="col-sm-10 ">
                  <div class="col-sm-10">{obj["Name"]}</div>
                  <div class="col-sm-10">{`Διευθυνση : ${obj["Location"]} `}</div>
                  <div class="col-sm-10">{obj["Memo"]}</div>
                </div>
            </div>
           </div>
        )});
  }
  //"AppointmentID", "TechnicalID", "AppDate", "Name", "Location", "Memo", "Status"
 async function onSave(obj){
    try {
      setError(null);
      const requestBody ={SaveObj: obj};
      let url=`${serverUrl}/api/Services/PostAppointment`;
      const result = await callApi('POST', url, null, requestBody);
      if (result.ErrorMessage ==='OK' ){
        setData(prevData=>{
          let newData=[...prevData];
          newData[editRowIndex]=obj;
          return newData;
        });
        setRefreshData(true);
      }
      else{
        setError(result.ErrorMessage);
      }
    } catch (error) {
      setError('Σφάλμα κατά τη σύνδεση:', error);
    }
  }

  function createTable() {
    if (!data && !routDate) return null;
    let rdate = dateToString(routDate);
    return (
      <div class="container tableview">
        <div class={'d-flex'}>
          <ErrorAlert message={error} ></ErrorAlert>
        </div>
        <div class="row Header">
            <div class="col-sm-12"> <p>Ραντεβού {rdate.toString()}</p></div>
        </div>

          {createRows()}
      </div>
    );
  }


  return (
    <div>
      <RouteForm
        active={editModeActive}
        onHide={() => setEditModeActive(false)}
        originalObj ={getOriginalObj}
        onSave={onSave}
      />
      {createTable()}
    </div>
  )
}

export default RoteList