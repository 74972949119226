import React, { useState } from 'react'
//import useApp from './AppContext';
//import { callApi, ErrorAlert,formatDate,formatDateValue,dateToString } from './utils/utils.js';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ScannControl from './components/ScannControl.js';
import { ErrorAlert } from './utils/utils.js';
function TableView(props) {
  const [error, setError] = useState(null);

  function Changebarcode(bc){
    setError(bc);
  }

  return (
    <div>
        <ErrorAlert message={props.layoutUrl} ></ErrorAlert>
        <ScannControl 
          disabled={ false } 
          onChange={e => Changebarcode(e)}
          >
        </ScannControl>
        <Form.Group key="" as={Row} className="label-and-control">
                <Col sm={12}>
                <Form.Control
                      as = "textarea" 
                      style={{ height: "20vh" }}
                      placeholder='Σχόλια' 

                    />
                </Col>
            </Form.Group>
        <ErrorAlert message={error} ></ErrorAlert>
    </div>
  )
}

export default TableView