import { useState } from 'react';
import { Link } from "react-router-dom";
function SideBarMenu(props){
    const [expandedOptions, setExpandedOptions] = useState([]);
    if (!props.options) return null;
    function onItemClick(e,option){
        e.preventDefault();
        let tmpExpandedOptions=[...expandedOptions];
        if (tmpExpandedOptions.find(o=>o===option)){
            tmpExpandedOptions.splice(option);
        }else{
            tmpExpandedOptions.push(option);
        }
        setExpandedOptions(tmpExpandedOptions);
    }
    function createLink(option,i){
        let link="/";
        if (option.link) link=option.link; 
        if (option.options){
            return <a key={'li'+i} href={link} onClick={(e)=>onItemClick(e,option)} data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">{option.caption}</a>
        }else{
            return <Link key={'li'+i} to={link}>{option.caption}</Link>
        }
    }
    function createListItem(option,i){
        let subOptions=null;
        if (option.options) {
            subOptions=option.options.map((o,j)=>createListItem(o,i.toString()+j));
            let className="list-unstyled";
            if (!expandedOptions.find(o=>o===option)) className+=" collapse"
            subOptions=<ul key={'ul'+i} className={className}>{subOptions}</ul>;
        }
        return <li key={option.caption}>
            {createLink(option,i)}
            {subOptions}
        </li>;
    }
    let listItems=props.options.map((o,i)=>createListItem(o,i));
    return <ul className="list-unstyled mb-5">
        {listItems}
  </ul>
}
export default SideBarMenu;