import { createContext, useContext, useState } from 'react';

const AppContext = createContext();

function AppContextProvider({ children }) {
    const [internalUser, setInternalUser] = useState(null);

    const setUser = (user) => {
        setInternalUser(user);
      }
      let value = {
        user: internalUser,   
        setUser} 
    return (
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
        );    
}

function useApp() {
    return useContext(AppContext);
  }
  
  export default useApp;
  export { AppContextProvider };