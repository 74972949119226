import React, { useState } from 'react';
import { callApi ,ErrorAlert } from './utils/utils.js';
import useApp from './AppContext';
import { useNavigate,  useLocation } from "react-router-dom";

function Login() {
    const app = useApp();
    const [serverUrl] = useState(process.env.REACT_APP_SERVER_URL);
    const [errorMessage, setErrorMessage] = useState(null);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    let navigate = useNavigate();
    let location = useLocation();
   
    let from = location.state?.from?.pathname || "/";

    async function handleKeyPress(event) {
        if (event.key === 'Enter') {
          requestLogin();
        }
      }


    async function requestLogin()  {
        try {
            setErrorMessage(null);
            const requestBody ={Username: username,Password: password };
            let url=`${serverUrl}/api/Services/Authenticate`;
            const result = await callApi('POST', url, null, requestBody);
            if (result.ErrorMessage !=='OK' ){
                setErrorMessage(result.ErrorMessage);
                return;
            }
            else{
                 app.setUser({UserID:result.UserID, technicalId: result.TechnicalID, warehouseId: result.WarehouseId, userName: result.UserName });
                 navigate(from, { replace: true });
            }
        } catch (error) {
            setErrorMessage('Σφάλμα κατά τη σύνδεση:', error);
        }
    }
/*
    {
        "UserID": 17.0,
        "WarehouseId": 1.0,
        "TechnicalID": 29.0,
        "UserName": "ΤΑΣΟΣ ΚΟΥΡΗΣ",
        "ErrorMessage": "OK"
    }
*/
    return (
<div class="container2">
            <div class="screen ">
                <div class="screen__content">
                 <div class="login">
                        <div class="login__field">
                            <i class="login__icon fas fa-user"></i>
                            
                            <input value={username} placeholder="Enter your Name here"
                                onChange={(ev) => setUsername(ev.target.value)}
                                className={'form-control'} />
                        </div>
                        <div class="login__field">
                            <i class="login__icon fas fa-lock"></i>
                            
                            <input value={password} placeholder="Enter your password here"
                            type="password"
                            onChange={(ev) => setPassword(ev.target.value)}
                            onKeyDown={handleKeyPress}
                            className={'form-control margintop'} />
                        </div>
                        <button class="button login__submit">
                        <input className={'btnSubmit'} type="button" onClick={requestLogin} value={'Log in'} />
                            <i class="button__icon fas fa-chevron-right"></i>
                        </button>	
                        <div class={'d-flex'}>
                            <ErrorAlert message={errorMessage} ></ErrorAlert>
                        </div>								
                    </div>
                    <div class="social-login">
                        <h3>Mparakos water</h3>
                    </div>
                </div>
            </div>
        </div>
      )

}

export default Login;